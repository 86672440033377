<template>
   <main class="main">
      <app-breadcrumbs last-element="Отзывы" />

      <section class="main__reviews reviews">
         <div class="container">
            <h2 class="reviews__section-title section-title">Отзывы клиентов</h2>

            <div v-if="reviews.length" class="reviews__wrap">
               <article v-for="item in reviews" :key="item.id" class="feedback">
                  <div class="feedback__wrap">
                     <img :src="item.imageURL" :alt="item.imageDescription" class="feedback__img" />

                     <div class="feedback__inner">
                        <h3 class="feedback__author">{{ item.author }}</h3>

                        <time :datetime="item.date" class="feedback__date">
                           {{ item.publicationDate }}
                        </time>
                     </div>
                  </div>

                  <p v-html="item.text" class="feedback__text"></p>
               </article>
            </div>

            <app-notifications v-if="!reviews.length" notification-type="Loading" />
         </div>
      </section>

      <app-pagination
         v-if="reviews.length"
         :max-items-count-on-page="maxReviewsCountOnPage"
         :items-count="reviewsCount"
         routeName="Reviews"
      />

      <section class="main__leave-feedback leave-feedback">
         <div class="container">
            <h2 class="leave-feedback__section-title section-title">Оставить отзыв</h2>

            <review-form @new-review="updateReviewsData" />
         </div>
      </section>
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import AppPagination from '@/components/templates/AppPagination';
   import ReviewForm from './modules/ReviewForm';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { setPublicationDate } from '@/components/_utils/setPublicationDate';
   import { checkQueryParams } from '@/components/_utils/checkQueryParams';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'Reviews',

      components: {
         AppBreadcrumbs,
         AppPagination,
         ReviewForm,
         AppNotifications
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            maxReviewsCountOnPage: 12
         };
      },

      mounted() {
         const { name, query } = this.$route;
         const result = checkQueryParams(name, query, ['page']);

         this.$router.replace(result);

         this.backToTopOfPage();
      },

      methods: {
         ...mapActions('reviews', ['fetchReviews']),

         updateReviewsData(value) {
            if (value) {
               this.fetchReviews({
                  page: this.$route.query.page,
                  limit: this.maxReviewsCountOnPage
               });

               this.$router.push({ name: 'Reviews', query: { page: 1 } });
               // this.backToTopOfPage();
            }
         },

         setPublicationDate(reviews) {
            reviews.forEach((review) => {
               review.publicationDate = setPublicationDate(review);
            });
         }
      },

      computed: {
         ...mapState('reviews', ['reviews', 'reviewsCount', 'errorMessage'])
      },

      watch: {
         errorMessage(error) {
            error?.status === 404 && this.$router.replace({ name: 'NotFound' }); //if
         },

         '$route.query.page': {
            handler(page) {
               if (page && this.$route.name === 'Reviews') {
                  this.fetchReviews({ page, limit: this.maxReviewsCountOnPage });
               }
            },
            immediate: true
         },

         reviews: {
            handler(value) {
               this.setPublicationDate(value);
            },
            immediate: true
         }
      }
   };
</script>
