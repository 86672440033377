<template>
   <form @submit.prevent="submitForm" class="review-form form" action="#" novalidate>
      <div class="review-form__wrap">
         <div class="review-form__inner">
            <app-field
               v-for="field in fields"
               :key="field.name"
               :is-form-submission="isFormSubmission"
               :is-displayed-error="isDisplayedError"
               :settings="field"
               v-model:field="field.data"
               @result-checking="saveResultChecking"
               @form-reset="resetForm"
            />
         </div>

         <app-textarea
            :is-form-submission="isFormSubmission"
            :is-displayed-error="isDisplayedError"
            :settings="textareaData"
            v-model:field="textareaData.data"
            @result-checking="saveResultChecking"
            @form-reset="resetForm"
         />
      </div>

      <button class="review-form__btn btn">Отправить</button>
   </form>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';
   import AppTextarea from '@/components/templates/FormFields/AppTextarea';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'ReviewForm',

      components: {
         AppField,
         AppTextarea
      },

      emits: {
         'new-review': (value) => typeof value === 'boolean'
      },

      inject: ['openModal'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            fields: [
               {
                  name: 'name',
                  type: 'text',
                  validationType: 'Name',
                  placeholder: 'Имя',
                  params: { minLength: 2, maxLength: 20 }
               },
               {
                  name: 'Email',
                  type: 'email',
                  validationType: 'Email',
                  placeholder: 'E-mail'
               }
            ],
            textareaData: {
               name: 'review',
               validationType: 'Message',
               placeholder: 'Отзыв',
               params: { minLength: 100, maxLength: 600 }
            },

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'ready',
               message: 'Спасибо за отзыв!',
               isActive: true
            }
         };
      },

      methods: {
         ...mapActions('reviews', ['fetchLastId', 'setReview']),

         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               this.addNewReview();

               this.openModal(this.modalSettings);
            } else {
               this.isDisplayedError = true;
            }
         },

         async addNewReview() {
            await this.fetchLastId();

            const review = {
               id: this.lastReviewId + 1,
               rating: +`${Math.random() * 100}`.slice(0, 1),
               date: this.setReviewDate(),
               author: this.fields[0].data,
               text: this.textareaData.data
            };

            const response = await this.setReview(review);
            this.$emit('new-review', response);
         },

         setReviewDate() {
            const date = new Date(Date.now());

            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth();
            const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

            return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}`;
         }
      },

      computed: {
         ...mapState('reviews', ['lastReviewId']),

         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
